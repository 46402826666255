import { useConstants } from 'src/constants/constantsContext';
import { useTranslation } from 'react-i18next';

export function useAppConstants() {
	const { constants } = useConstants();

	return constants?.data.constants;
}

export function useConstantsTranslation(language: string = 'de') {
	const { constants } = useConstants();

	return constants?.data.translations[language].constants;
}

export function useTranslations(language: string = '') {
	if (!language) {
		const { i18n } = useTranslation();
		language = i18n.language;
	}
	const { constants } = useConstants();

	return constants?.data.translations[language];
}

const createRegex = (tag: string) => new RegExp(`{${tag}}(.*?){\\/${tag}}`, 'g');

export function replaceTags(str = '', img: any = null, bottom = '10') {
	if (typeof str !== 'string') {
		console.error('Expected a string, but received:', typeof str);
		return '';
	}

	const highlight = 'highlight';
	const underline = 'underline';

	const highlightRegex = createRegex(highlight);
	const underlineRegex = createRegex(underline);

	str = str.replace(highlightRegex, '<span class="text-primary">$1</span>');

	str = str.replace(underlineRegex, (match, p1) => {
		return `<div class="inline relative">${p1}<img class="w-full absolute right-0 bottom-[-${bottom}px]" src="${img}" alt="green_line" title="green_line" /></div>`;
	});

	return str;
}

export function replaceLinkTags(str: string, options: any = {}) {
	if (typeof str !== 'string') {
		console.error('Expected a string, but received:', typeof str);
		return '';
	}

	const { termsUrl = '#', privacyUrl = '#' } = options;

	const terms_link = 'terms_link';
	const privacy_link = 'privacy_link';

	const termsLinkRegex = createRegex(terms_link);
	const privacyLinkRegex = createRegex(privacy_link);

	str = str.replace(
		termsLinkRegex,
		`<a href="${termsUrl}" target="_blank" rel="noreferrer" class="text-primary">$1</a>`
	);

	str = str.replace(
		privacyLinkRegex,
		`<a href="${privacyUrl}" target="_blank" rel="noreferrer" class="text-primary">$1</a>`
	);

	return str;
}

export function replaceContactLinkTags(str: string, options: any = {}) {
	if (typeof str !== 'string') {
		console.error('Expected a string, but received:', typeof str);
		return '';
	}

	const { mailHref = '#', numberHref = '#' } = options;

	const mail_href = 'mail_href';
	const number_href = 'number_href';

	const mailHrefRegex = createRegex(mail_href);
	const numberHrefRegex = createRegex(number_href);

	str = str.replace(mailHrefRegex, `<a href="${mailHref}"class="text-primary">$1</a>`);

	str = str.replace(numberHrefRegex, `<a href="${numberHref}"class="text-primary">$1</a>`);

	return str;
}
